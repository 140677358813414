<template>
     <div class="col-xs-6 col-md-3 col-sm-12">
        <b-card-img
            v-if="product.modelType == 'rates'"
            style="height: 100%"
            :src="imgUrlExperience + product.coverImage"
            @error="imgAlternativo"
        />
        <b-card-img
            v-if="product.modelType == 'transfer'"
            style="height: 100%"
            :src="imgUrlTransfer + product.coverImage"
            @error="imgAlternativo"
        />
        <b-card-img
            v-if="product.modelType != 'booking' && product.modelType != 'rates' && product.modelType != 'transfer'"
            style="height: 100%"
            :src="imgUrl + product.coverImage"
            @error="imgAlternativo"
        />
        <b-card-img
            v-if="product.modelType == 'booking'"
            style="height: 100%"
            :src="product.img"
            @error="imgAlternativo"
        />

  
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: true,
        },       
    },
    data(){
        return {
            imgUrl: process.env.VUE_APP_IMG_SRC_API,
            imgUrlExperience: process.env.VUE_APP_IMGEXP_SRC_API,
            imgUrlTransfer: process.env.VUE_APP_IMGETRF_SRC_API,
            imdDefault: require("@/assets/images/default.jpg"),
        }
    },
    methods:{
        imgAlternativo(event) {
            event.target.src = this.imdDefault
        },
    }
}
</script>