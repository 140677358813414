<template>
    <div class="item-options text-center" >
        <div class="ribbon ribbon-top-right">
            <span>Reserva {{ onlyKeyBookings.indexOf(product.uuid) + 1 }}</span>
        </div>
        <br>
        <div class="item-wrapper">
            <div class="item-cost mt-1">
                <h5 class="item-price">Tarifa: {{ product.tarifa }}</h5>
            </div>
            <div class="item-cost mt-1 text-center">
                <h5 class="item-price" v-if="!isReloadingProductsInCart">{{product.displayrates.currency}} {{ subTotalProductoQty(product) }}</h5>
                <b-skeleton width="75%" class="" v-else></b-skeleton>

            </div>
        </div>
        <b-row class="mt-1">
            <b-col>
                <b-button
                    variant="danger"
                    class="btn-sm"
                    @click="deleteItem(product)"
                > <feather-icon icon="TrashIcon" />
                </b-button>
            </b-col>

            <b-col>
                <b-button
                    variant="success"
                    class="btn-sm"
                    :ref="'btnShowProduct' + product.uuid"
                    @click="openModalEditBooking(product)"
                > <feather-icon icon="EditIcon" />
                </b-button>
            </b-col>
        </b-row>

        <b-col cols="12 mt-1">
            <b-form-group label="Pagos parciales">
                <b-form-select
                    :options="options"
                    v-model="product.partial"
                    @change="setDataInNights(product)"
                />
            </b-form-group>
        </b-col>

        <div v-if="product.partial == 1">
            <b-row>
                <b-col cols="6">
                    <b-form-group label="Noches">
                        <b-form-select
                            v-model="product.nightsNum"
                            @change="setPriceNights(product)"
                        >
                            <option v-for="(night,index) in product.nights" :key="index" :value="night">{{night}}</option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="6">
                    <b-form-group label="Precio">
                        <b-form-input v-if="!isReloadingProductsInCart"
                            v-model="product.displayrates.firstPay"
                            placeholder="Precio"
                            :disabled="true"
                            type="text"
                        />
                        <b-skeleton type="input" v-else></b-skeleton>
                    </b-form-group>
                </b-col>
        </b-row>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import { market } from '@/modules/shop/mixins/market'
import { globalCurrencyFormat } from "@/helpers/helpers";

export default {
    mixins: [market],
    props: {
        product: {
            type: Object,
            required: true,
        },

    },
    computed: {
        ...mapState("shop", ["isReloadingProductsInCart"]),
        ...mapGetters("shop",["onlyKeyBookings"])
    },
    data(){
        return {
            imgUrl: process.env.VUE_APP_IMG_SRC_API,
            imdDefault: require("@/assets/images/default.jpg"),
            options: [
            { text: "No", value: 0 },
            { text: "Si", value: 1},
        ],
        }
    },
    methods:{
        ...mapActions('shop',['calculateTotalWithDiscounts']),
        ...mapMutations("shop", ["calculateTotalNights"]),
        subTotalProductoQty(item) {
            const subTotal = (item.displayrates.netSale).toFixed(2)
            return globalCurrencyFormat(subTotal)
        },
        deleteItem(item) {
            this.manageRemoveProductInCart(item)
        },
        openModalEditBooking(item) {
            const modal = "modal-edit-booking" + item.uuid;
            const btnShowBooking = "btnShowBooking" + item.uuid;
            this.$root.$emit("bv::show::modal", modal, btnShowBooking);
        },
        async setDataInNights(product) {
            await this.calculateTotalNights(product)
            this.nightsTotal = product.nightsTotal
            product.nightsNum = product.nights
            await this.setPriceNights(product)
        },
        async setPriceNights(item) {
            const total = item.saleprice / item.nights
            const nightsTotalPrice = item.nightsNum * total
            item.firstPay = (nightsTotalPrice).toFixed(2)

            const totalcurrency = item.displayrates.saleprice / item.nights
            const nightsTotalPriceCurrency = item.nightsNum * totalcurrency
            item.displayrates.firstPay = (nightsTotalPriceCurrency).toFixed(2)

            await this.calculateTotalWithDiscounts(item)
        },
    }
}
</script>
<style scoped src="@/assets/css/styles-checkout-products.css"></style>
