<template>
  <div class="item-options text-center">
    <div class="ribbon ribbon-top-right">
      <span>Propietario {{ onlyKeyOwnerBookings.indexOf(product.uuid) + 1 }}</span>
    </div>
    <br />
    <div class="item-wrapper">
      <div class="item-cost mt-1">
        <h5 class="item-price">Tarifa: {{ product.tarifa }}</h5>
      </div>
      <div class="item-cost mt-1">
        <h5 class="item-price">{{product.displayrates.currency}} {{ subTotalProductoQty(product) }}</h5>
      </div>
    </div>
    <b-row class="mt-1">
      <b-col class="custom-margin">
        <b-button v-b-tooltip.hover title="Descartar reserva" variant="danger" class="btn-sm" @click="deleteItem(product)">
          <feather-icon icon="TrashIcon" />
        </b-button>
      </b-col>

      <b-col class="custom-margin">
        <b-button v-b-tooltip.hover title="Editar Huéspdes" variant="success" class="btn-sm" @click="openModalEditBooking(product)">
          <feather-icon icon="UsersIcon" />
        </b-button>
      </b-col>

      <b-col class="custom-margin">
        <b-button v-b-tooltip.hover title="Editar Reserva" variant="warning" class="btn-sm" :to="{ name: 'OwnersBooking', params: dataFiltersBack}">
          <feather-icon icon="EditIcon" />
        </b-button>
      </b-col>
    </b-row>

    <hr>

    <div v-if="product.bothBenefits">
      <b-carousel :interval="0" controls>
        <b-carousel-slide v-for="(benefit, index) in product.bothBenefits" :key="index" >
          <template #img>
            <b-row class="small-letter">
              <b-col class="12">
                <!-- <span>Código del beneficio: </span><br> -->
                <span><strong>{{benefit.benefitCode}}</strong></span><br>
              </b-col>
            </b-row>
            <b-row class="small-letter mb-1">
              <b-col>
                <span>Noches: </span><br>
                <span><strong>{{benefit.total}}</strong></span><br>
              </b-col>
              <b-col>
                <span>Disponibles: </span><br>
                <span><strong>{{benefit.saldo}}</strong></span><br>
              </b-col>
              <b-col>
                <span>Usadas: </span><br>
                <span><strong>{{benefit.used}}</strong></span><br>
              </b-col>
              <b-col>
                <strong>A aplicar: </strong><br>
                <span><strong>{{product.nightsUpgrade  != 0 ? product.nightsUpgrade :product.nights }}</strong></span><br>
              </b-col>
            </b-row>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>

    <div v-else-if="product.benefitSelected">
      <b-row class="small-letter">
        <b-col class="12">
          <span>Código del beneficio: </span><br>
          <span><strong>{{product.benefitSelected.code}}</strong></span><br>
        </b-col>
      </b-row>
      <b-row class="small-letter">
        <b-col>
          <span>Noches: </span><br>
          <span><strong>{{product.benefitSelected.benefitqty}}</strong></span><br>
        </b-col>
        <b-col>
          <span>Disponibles: </span><br>
          <span><strong>{{product.benefitSelected.balance}}</strong></span><br>
        </b-col>
        <b-col>
          <span>Usadas: </span><br>
          <span><strong>{{product.benefitSelected.used,}}</strong></span><br>
        </b-col>
        <b-col>
          <strong>A aplicar: </strong><br>
          <span><strong>{{product.nightsUpgrade  != 0 ? product.nightsUpgrade :product.nights }}</strong></span><br>
        </b-col>
      </b-row>

      <!-- <div class="alert-msg alert-msg__danger" v-if="product.needAuthorization">
        <hr>
        <label class="alert-p text-warning small-letter">
          <feather-icon icon="CircleIcon" size="8" class="mr-30" />
          La reserva tendra que pasar por una autorización previa para poder confirmarla
        </label>
      </div> -->

      <hr v-if="product.blackout > 0 && product.highSeason">

      <b-row class="small-letter" v-if="product.blackout > 0 && product.highSeason">
        <b-col class="12">
          <span>Código del beneficio: </span><br>
          <span><strong>{{product.highSeason.code}}</strong></span><br>
        </b-col>
      </b-row>
      <b-row class="small-letter" v-if="product.blackout > 0 && product.highSeason">
        <b-col>
          <span>Noches: </span><br>
          <span><strong>{{product.highSeason.benefitqty}}</strong></span><br>
        </b-col>
        <b-col>
          <span>Disponibles: </span><br>
          <span><strong>{{product.highSeason.balance}}</strong></span><br>
        </b-col>
        <b-col>
          <span>Usadas: </span><br>
          <span><strong>{{product.highSeason.benefitqty - product.highSeason.balance}}</strong></span><br>
        </b-col>
        <b-col>
          <strong>A aplicar: </strong><br>
          <span><strong>{{product.blackout }}</strong></span><br>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters,mapState } from "vuex"
import { market } from '@/modules/shop/mixins/market'
import { globalCurrencyFormat } from '@/helpers/helpers'

export default {
  mixins: [market],
  async mounted() {
    this.setDataInNights(this.product.benefitSelected)
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('shop', ['onlyKeyOwnerBookings']),
    ...mapState('fivesClubCatalogs', ['bookingInfo']),
    ...mapState('appConfig',['windowWidth']),
    dataFiltersBack(){
      const {
        idHotel, housingnumber, nights, rangeDate, adults,
        numberChildren, internalNotes, ninos, dateIn, dateOut,
        needAuthorization, reasonAuthorization, blackout
      } = this.product
      return {
        toEdit: true,
        hotel: idHotel == 5 ? 3 : idHotel,
        numberHouse: housingnumber,
        rangeDate,
        nights,
        adultsNumber: adults,
        numberChildren,
        internalNotes,
        ninos,
        dateIn,
        dateOut,
        needAuthorization,
        reasonAuthorization,
        blackout
      }
    }
  },
  data() {
    return {
      nightsTotal:0,
      benefits:'',
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      imdDefault: require('@/assets/images/default.jpg'),
      options: [
        { text: 'No', value: 0 },
        { text: 'Si', value: 1 },
      ],
    }
  },
  methods: {
    ...mapActions('shop',['calculateTotalWithDiscounts']),
    ...mapMutations('shop', ['calculateTotalNights', 'aplicateDescountReset', 'aplicateDescountBenefitReserv' ]),
    subTotalProductoQty(item) {
      const subTotal = (item.netSale).toFixed(2)
      return globalCurrencyFormat(subTotal)
    },
    deleteItem(item) {
      this.manageRemoveProductInCart(item)
    },
    openModalEditBooking(item) {
      const modal = 'modal-edit-booking' + item.uuid
      const btnShowBooking = 'btnShowBooking' + item.uuid
      this.$root.$emit('bv::show::modal', modal, btnShowBooking)
    },
    async setDataInNights(benefitsId) {
      const benefits = this.product.benefits
      let benefitsNights =benefits?.find(item => item.benefit === benefitsId)
      if (benefitsId==""){
        this.aplicateDescountReset( this.product.id)
        await this.calculateTotalWithDiscounts(this.product)
        return false
      }
      this.nightsTotal=benefitsNights?.balance
      const balance=benefitsNights?.balance
      this.setPriceNights(balance,benefitsNights)
    },
    async setPriceNights(balance,benefitsNights) {
      const item= this.product

      const payload={
        uuid:item.uuid,
        benefitsNights,
        desc:100,
        name:1
      }

      this.aplicateDescountBenefitReserv(payload)
      await this.calculateTotalWithDiscounts(item)
    },
  },
}
</script>
<style scoped src="@/assets/css/styles-checkout-products.css"></style>
<style>
.small-letter{
  font-size: 11px;
}
.custom-margin{
  margin: 0.3rem;
  color: #9d9c9ca4;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 0.66rem !important;
  width: 0.66rem !important;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23686868a4' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  bottom: 0.01rem;
  position: absolute;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23686868a4' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  bottom: 0.01rem;
  position: absolute;
}

.carousel-indicators li {
    border-color: #9d9c9ca4;
    border-width: 12px;
    border-style: none solid none solid;
    background-color: #9d9c9ca4;
    height: 2px;
    margin: 0 16px;
    opacity: 1;
    padding: 1px;
    position: relative;
  }
  .carousel-indicators li::after {
    bottom: -7px;
    content: none;
    left: -7px;
    padding: 1px;
    position: absolute;
    right: -7px;
    top: -7px;
  }
  .carousel-indicators li.active {
    background-color: #686868a4;
    border-color: #686868a4;
    border-width: 12px;
    border-style: none solid none solid;
  }
</style>
