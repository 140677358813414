<template>
  <div class="checkout-options" v-if="cart.cliente.currency">
    <b-card no-body class="border-primary" >
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
        <h5 class="mb-0">
          Total de la compra
        </h5>
        <b-badge variant="success">
          {{currency.code}}
        </b-badge>
        <!-- <small class="text-muted w-100">Elija una divisa para la conversión</small> -->
      </b-card-header>
      <b-card-body>
        <b-row class="mt-1">
          <b-col cols="12" v-if="currencySubTotalParcial != 0">
            <div class="client-card d-flex justify-content-between margin-customer-card" v-if="isReloadingProductsInCart">
              <b-skeleton width="28%"></b-skeleton><b-skeleton width="24%"></b-skeleton>
            </div>
            <div class="client-card d-flex justify-content-between margin-customer-card" v-else>
              <span class="">Primer pago parcial:</span> {{ formatAmount(currencySubTotalParcial) }}
            </div>
          </b-col>
          <b-col cols="12" v-if="currencySubTotalParcial != 0">
            <div class="client-card d-flex justify-content-between margin-customer-card border-subtotals" v-if="isReloadingProductsInCart">
              <b-skeleton width="28%"></b-skeleton><b-skeleton width="24%"></b-skeleton>
            </div>
            <div class="client-card d-flex justify-content-between margin-customer-card border-subtotals" v-else>
              <span class="">Otros cargos:</span> {{ formatAmount(currencySubTotalParcialBooking) }}
            </div>
          </b-col>
          <b-col cols="12">
            <div class="client-card d-flex justify-content-between margin-customer-card" v-if="isReloadingProductsInCart">
              <b-skeleton width="28%"></b-skeleton><b-skeleton width="24%"></b-skeleton>
            </div>
            <div class="client-card d-flex justify-content-between margin-customer-card" v-else>
              <span class="">Subtotal:</span> {{ formatAmount(currencySubTotal) }}
            </div>
          </b-col>
          <b-col cols="12">
            <div class="client-card d-flex justify-content-between margin-customer-card" v-if="isReloadingProductsInCart">
              <b-skeleton width="28%"></b-skeleton><b-skeleton width="24%"></b-skeleton>
            </div>
            <div class="client-card d-flex justify-content-between margin-customer-card" v-else>
              <span class="">Descuento:</span> {{ formatAmount(currencyTotalDiscount) }}
            </div>
          </b-col>
          <b-col cols="12">
            <div class="client-card d-flex justify-content-between margin-customer-car border-totals mb-1 font-weight-bolderd" v-if="isReloadingProductsInCart">
              <b-skeleton width="20%" class="mt-1"></b-skeleton><b-skeleton width="34%" class="mt-1"></b-skeleton>
            </div>
            <div class="client-card d-flex justify-content-between margin-customer-card border-totals mb-1 font-weight-bolder" v-else>
              <span>Total:</span> <div><span v-if="isAValidCurrency">{{currency.code}}</span> {{ formatAmount(currencyTotal) }}</div>
            </div>
          </b-col>
        </b-row>

        <div class="checkout-options p-1 mb-1 border-danger rounded" v-if="cart.requiredFields.length > 0">
          <!-- <hr> -->
          <label>Por favor, llene las casillas de los siguientes artículos: </label> <br>
          <span v-for="(requiredFields, index) in cart.requiredFields" :key="index">
            <label>
              <strong>{{ index + 1 }} -  </strong> {{ requiredFields}}
            </label> <br>
          </span>
        </div>

        <b-row>
          <b-col v-if="bookingSinAuthorization > 0">
            <b-button variant="primary" @click="openModalAuthorizeBooking">
              <feather-icon icon="UnlockIcon"/> Autorizar bookings
            </b-button>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              block
              :to="{ name: 'payment' }"
              :disabled="disablePaymentFlow || isReloadingProductsInCart"
            > Finalizar Venta
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <ModalAuthorize/>
  </div>

</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import ModalAuthorize from "@/modules/shop/components/orderSummary/ModalAuthorize"
import { toJson, globalCurrencyFormat } from '@/helpers/helpers'

export default {
  components:{
    ModalAuthorize
  },
  data() {
    return {
      currencieValor:1
      // imgUrl: process.env.VUE_APP_IMG_SRC_API,
    }
  },
  computed:{
    ...mapState('shop',['cart', 'currency', 'isReloadingProductsInCart']),
    ...mapState('start',['currencies']),
    ...mapGetters('shop',['totalBreakdown','currencyTotal', 'currencySubTotal', 'currencySubTotalParcial', 'currencySubTotalParcialBooking', 'currencyTotalDiscount', 'tc']),
    bookingSinAuthorization(){
      const cart = this.cart.items
      return cart.filter( b => b.modelType === "booking" && b.requiresAuthorization ).length
    },
    disablePaymentFlow(){
      return this.cart.items.length == 0 || this.cart.requiredFields.length > 0
    },
    isAValidCurrency(){
      const notEmpty = this.cart?.cliente?.currency?.value > 0

      return !isNaN(this.cart?.cliente?.currency?.value) && notEmpty

    }
  },
  methods:{
    ...mapMutations('shop',['setCurrency']),
    calcularImportes(){
      this.currencieValor = this.currency ? this.currency.value : 1
      this.setCurrency(this.currency)
    },
    setTab(){
      this.$emit('set-tab', 1)
    },
    openModalAuthorizeBooking() {
      const modal = "authorize-booking"
      const btnShowBooking = "btnShowBooking"
      this.$root.$emit("bv::show::modal", modal, btnShowBooking)
    },
    formatAmount(amount){
      return globalCurrencyFormat(amount)
    },
  },
  watch: {
    totalBreakdown: {
      deep: true,
      handler() {
        if( this.totalBreakdown === '0.00' && this.currencies.length > 0 ) {
          const divisa = toJson( this.currencies.find( currency => currency.code === 'USD' ))
          this.setCurrency( divisa )
        }
      },
    },
    currency:{
      deep: true,
      handler(){
        this.calcularImportes()
      }
    }
  },
}
</script>


<style lang="scss" scoped>

.card-img-top {
  width: 100%;
  height: 25vh;
  object-fit: cover;
}
.ecommerce-application .grid-view .ecommerce-card .item-img {
    padding-top: 0rem;
    min-height: 0rem;
    display: flex;
    align-items: center;
}

.totalParcial{
color:crimson
}

.client-card{
  margin: 0.1rem 0;
}
.border-totals{
  border-top: 1px solid rgb(148, 148, 148);
  // padding: 0;
  // margin: 0 1rem;
}

.border-subtotals{
  border-bottom: 1px solid rgb(148, 148, 148);
  // padding: 0;
  // margin: 0 1rem;
}
</style>